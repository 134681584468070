import React, { useState } from 'react';
// import { Button, Container, Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import MessageWindow from './MessageWindow';
import httpClient from '../utils/httpClient';
import Disclaimer from './Disclaimer';
import '../css/SigninSignup.css';
import PageTitle from '../utils/PageTitle';

const Signup = () => {
  PageTitle("Sign up | Traceorg")
  const [formData, setFormData] = useState({
    email: '',
    // username: '',
    password: ''
  });
  const [message, setMessage] = useState();
  const [agreeDisclaimer, setAgreeDisclaimer] = useState(false);
  const [errors, setErrors] = useState({});

  const handleCheckAgree = (e) => {
    setAgreeDisclaimer(e.target.checked);
  };

  const signupUser = async () => {
    try {
      // await httpClient.post("//localhost:5050/signup", formData);
      // await httpClient.post("https://upload.traceorg.com/signup", formData);
      await httpClient.post(`${window.apiUrl}/signup`, formData);
      window.location.href = "/";
    } catch (error) {
      console.log(error.response)
      if (error.response.status === 409) {
        setMessage("User already exists");
      }
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const validateForm = () => {
    console.log('inside validateform', formData)
    let errors = {};
    let formIsValid = true;

    if (!formData.email) {
      formIsValid = false;
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formIsValid = false;
      errors.email = 'Please enter a valid email';
    }

    // if (!formData.username) {
    //   formIsValid = false;
    //   errors.username = 'Please enter your username.';
    // } else if (formData.username && !/^[a-zA-Z0-9_]{3,16}$/.test(formData.username)) {
    //   formIsValid = false;
    //   errors.username = 'Please enter a valid name between 3-16 characters. Use letters, numbers, and underscores only. No spaces allowed.';
    // }

    if (!formData.password) {
      formIsValid = false;
      errors.password = 'Please enter your password.';
    } else if (formData.password && !/^(?=.*[a-z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/.test(formData.password)) {
      formIsValid = false;
      errors.password = 'Invalid password. Password must be at least 8 characters long and include at least one letter and one number.';
      console.log(formIsValid)
    }

    setErrors(errors);
    return formIsValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      await signupUser();
    }
  }

  return (
    <>
      <div className="center-wrapper">
        <Container className="login-form-div">
          <Form className="login-form" onSubmit={handleSubmit}>
            <h2>Sign up</h2>
            <Form.Group controlId="email">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter email"
                isInvalid={!!errors.email} />
              <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
            </Form.Group>

            {/* <Form.Group controlId="username">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="username"
                name="username"
                value={formData.username}
                onChange={handleChange}
                placeholder="Enter your username"
                isInvalid={!!errors.username} />
              <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
            </Form.Group> */}

            <Form.Group controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Password"
                isInvalid={!!errors.password} />
              <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
            </Form.Group>

            {/* <br/> */}
            <label className='disclaimer-label'>
              <input
                type="checkbox"
                checked={agreeDisclaimer}
                onChange={handleCheckAgree}
              />
              I agree to the <a className="green-link" href="#"
                onClick={() => { setMessage(<Disclaimer />) }}>
                disclaimer
              </a>.
            </label>
            <br />
            <Button
              type="submit"
              className="signin-button"
              disabled={!agreeDisclaimer}
            >
              Sign Up
            </Button>

            <Button variant="secondary" href="/">
              Cancel
            </Button>

          </Form>
          <a className='signup-link' href="/login">Already have an account? Sign in</a>
        </Container>


      </div>
      {message && <MessageWindow message={message} onClose={() => { setMessage(null) }} />}
    </>
  );
};

export default Signup;
