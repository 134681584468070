import { Modal, Button } from "react-bootstrap";
 
const DeleteConfirmation = ({confirmDelete, subject_id, message, onClose }) => {
    return (
        <Modal show={true} onHide={onClose} centered scrollable={true}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="alert alert-danger">
            {message}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => confirmDelete(subject_id) }>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    )
}
 
export default DeleteConfirmation;