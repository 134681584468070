import React, { useState, useEffect } from 'react';
// import { Table, Form, Pagination, Button, Row, Col } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup  from 'react-bootstrap/InputGroup';
import httpClient from '../utils/httpClient';
import MessageWindow from './MessageWindow';
import LoadingWindow from './LoadingWindow';
import PageTitle from '../utils/PageTitle';
import DeleteConfirmation from '../views/DeleteConfirmation';
import SubmitConfirmation from '../views/SubmitConfirmation';
import DropdownPagination from './DropdownPagination';

const StudyList = () => {
    PageTitle('List | Traceorg');

    const [studies, setStudies] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10); // Set the number of items per page
    const [message, setMessage] = useState();
    const [loadingMessage, setLoadingMessage] = useState();
    const [sortConfig, setSortConfig] = useState({ key: 'insert_date', direction: 'descending', type: 'string' });
    const [adminFunctions, setAdminFunctions] = useState(null);

    useEffect(() => {
        (async () => {
            try {
                // const resp = await httpClient.get("//localhost:5050/user");
                // const resp = await httpClient.get("https://upload.traceorg.com/user");
                const resp = await httpClient.get(`${window.apiUrl}/user`);
                if (resp.data.id == 2 || resp.data.id == 4 || resp.data.id == 7) {
                    setAdminFunctions(true)
                }
            } catch (error) {
                setLoadingMessage("Please login first. Redirecting to login page...");
                // display for 2.5 seconds
                setTimeout(() => {
                    setLoadingMessage(null);
                    window.location.href = "/login";
                }, 2500); 
            }
        })()
        fetchStudies();
        const intervalId = setInterval(fetchStudies, 60000); // Fetch studies every minute
        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, []);

    const fetchStudies = async () => {
        try {
            // const response = await httpClient.get('//localhost:5050/get_experiments');
            // const response = await httpClient.get('https://upload.traceorg.com/get_experiments');
            const response = await httpClient.get(`${window.apiUrl}/get_experiments`);

            const processList = (list) => {
                return list.map(item => {
                    // label format: user_id - lastName_firstName - study_uid
                    const parts = item.label.split('-');

                    // Make sure the parts array has at least 4 elements to avoid errors
                    if (parts.length < 3) return null;

                    const [lastName, firstName,] = parts[1].split("_")
                    //   const firstName = parts[2];
                    const study_uid = parts[2];

                    // subject_label format: user_id - patientname - mrn
                    const MRN = item.subject_label.split('-')[2];

                    // Return a new object in the specified format
                    return {
                        'patient_name': `${firstName} ${lastName}`,
                        'study_uid': study_uid,
                        'mrn': MRN,
                        'study_date': item.date,
                        'description': '',
                        'experiment_id': item.ID,
                        'subject_id': item.subject_ID,
                        'note': item.note,
                        'insert_date': item.insert_date,
                        'label': item.label,
                        'modality': item.modality
                    };
                }).filter(item => item !== null); // Filter out any possible nulls due to incorrect data
            };

            setStudies(processList(response.data));

        } catch (error) {
            console.error('Error fetching studies:', error.response.status);
            if (error.response.status === 404) {
                setStudies([])
            }
        }
    };

    const handleDownload = async (subject_id, experiment_id, filetype) => {
        let filename = filetype === 'segmentation' ? '' : 'report'
        let data = {
            "subject_id": subject_id,
            "experiment_id": experiment_id,
            "filename": filename
        }

        let params = Object.keys(data).map(key => key + '=' + encodeURIComponent(data[key])).join('&');

        // Display the loading message window
        setLoadingMessage("Please wait while we are retrieving your reports...");
        // httpClient('http://localhost:5050/get_results?' + params, {
        // httpClient('https://upload.traceorg.com/get_results?' + params, {
        httpClient(`${window.apiUrl}/get_results?` + params, {
            method: 'GET',
            responseType: 'blob'
        }).then(response => {
            const url = window.URL.createObjectURL(response.data);
            if (filetype === 'report') {
                window.open(url, '_blank')
            } else {
                const a = document.createElement('a');
                a.href = url;
                a.download = filetype === 'report' ? 'report.pdf': 'segmentation_results.zip'; // Specify the filename
                document.body.appendChild(a);
                a.click();
                // Clean up
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }      
        }).catch(error => {
            if (error.response.status === 404) {
                if (filetype === 'segmentation') {
                    setMessage("Your segmentation result is not available right now. Please try again later.");
                } else {
                    setMessage("Your report is not ready yet. Please try again later.");
                }   
            }
        })
        .finally(() => {
            setLoadingMessage(null);
        })
    }

    const [deleteMessage, setDeleteMessage] = useState(null);
    const [deleteID, setDeleteID] = useState(null);

    const handleDelete = (subject_id, study_uid, patient_name) => {
        setDeleteMessage('Are you sure you want to delete this study for patient "' + patient_name + '"?');
        setDeleteID(subject_id)
    }

    const confirmDelete = async(subject_id) => {
        // httpClient('http://localhost:5050/delete_study?subject_id=' + subject_id, {
        // httpClient('https://upload.traceorg.com/delete_study?subject_id=' + subject_id, {
        httpClient(`${window.apiUrl}/delete_study?subject_id=` + subject_id, {
            method: "DELETE"
        }).then(response => {
            setDeleteMessage(null)
            setMessage("The study is successfully deleted.")
            setTimeout(() => {
                setMessage(null)
            }, 2000)
        }).catch(error => {
            if (error.response.status === 404) {
                setMessage("This study is alreadly deleted.");
            }
        }).finally(() => {
            fetchStudies();
        })
    }

    const [submitMessage, setSubmitMessage] = useState(null);
    const [submitData, setSubmitData] = useState(null);

    const handleSubmitWorkflow = (subject_id, experiment_id, label) => {
        setSubmitMessage('Re-running the inference will overwrite what have been run for this case \
            with session label ' + label)
        let data = {
            "session_id": experiment_id,
            "session_label": label,
            "subject_id": subject_id,
            "session": "/archive/experiments/"+experiment_id,
            "project": "trace"
        }
        console.log(data)
        setSubmitData(data)
    }

    const confirmSubmit = async(data) => {
        // httpClient.post('http://localhost:5050/submit_workflow', data, {
        // httpClient.post('https://upload.traceorg.com/submit_workflow', data, {
        httpClient.post(`${window.apiUrl}/submit_workflow`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(response => {
            setSubmitMessage(null)
            setMessage("The job is submitted successfully.")
            setTimeout(() => {
                setMessage(null)
            }, 2000)
        }).catch(error => {
            setMessage("Failed to submit.");
        }).finally(() => {
            setSubmitMessage(null)
        })
    }

    const handleSort = (key, type="string") => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction, type });
    };

    const sortedStudies = [...studies].sort((a, b) => {
        let val_a = a[sortConfig.key]
        let val_b = b[sortConfig.key]
        if (sortConfig.type == 'date') {
            val_a = new Date(val_a)
            val_b = new Date(val_b)
        }
        if (val_a < val_b) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (val_a > val_b) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
    });

    // Calculate the current studies to display based on pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentStudies = sortedStudies.slice(indexOfFirstItem, indexOfLastItem);

    // Create pagination items
    const totalPages = Math.ceil(sortedStudies.length / itemsPerPage);
    const paginationItems = [];
    for (let number = 1; number <= totalPages; number++) {
        paginationItems.push(
            <Pagination.Item key={number} active={number === currentPage} onClick={() => setCurrentPage(number)}>
                {number}
            </Pagination.Item>
        );
    }

    return (
        <div className='center-wrapper exception'>
            <div style={{ marginTop: "20px", width:'100%' }}>
                <div style={{ marginBottom: "20px" }}>
                    <Button variant="outline-primary" size="lg" href='/uploadstudy'>Back to upload studies</Button>
                    <div style={{ marginTop: "15px" }}>Here is the <a href="/instructions">instructions</a> on how to view and correct the segmentation results.</div>
                    {/* <Form >
                        <Row >
                            <Form.Group as={Col} controlId="field1">
                                <Form.Control
                                    type="text"
                                    placeholder="Patient Name"
                                    value={searchParams.patient_name}
                                    onChange={(e) => handleSearchChange(e, 'patient_name')}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="field1">
                                <Form.Control
                                    type="text"
                                    placeholder="MRN"
                                    value={searchParams.mrn}
                                    onChange={(e) => handleSearchChange(e, 'mrn')}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="field1">
                                <Form.Control
                                    type="text"
                                    placeholder="Study Date"
                                    value={searchParams.study_date}
                                    onChange={(e) => handleSearchChange(e, 'study_date')}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="field1">
                                <Form.Control
                                    type="text"
                                    placeholder="Study UID"
                                    value={searchParams.study_uid}
                                    onChange={(e) => handleSearchChange(e, 'study_uid')}
                                />
                            </Form.Group>
                        </Row>
                    </Form> */}
                </div>

                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th className='modality-cell'>Modality</th>
                            <th className='six-rem-cell' onClick={() => handleSort('patient_name')}>
                            Patient Name {sortConfig.key === 'patient_name' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : ''}
                            </th>
                            <th>MRN</th>
                            <th onClick={() => handleSort('insert_date')}>
                                Upload Time {sortConfig.key === 'insert_date' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : ''}
                            </th>
                            <th onClick={() => handleSort('study_date')}>
                                Study Date {sortConfig.key === 'study_date' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : ''}
                            </th>
                            <th>Study UID</th>
                            <th className='eight-rem-cell'>Segmentation Results</th>
                            <th className='eight-rem-cell'>Report</th>
                            {adminFunctions && <th className='modality-cell'>Delete Study</th>}
                            {adminFunctions && <th className='modality-cell'>Submit Workflow</th>}
                            {adminFunctions && <th>Email</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {currentStudies.map((study) => {
                            let note = study.note || 'unknown';
   
                            const segmentationStatus = note.segmentation_status || 'unknown';
                            const reportStatus = note.report_status || 'unknown';
                            const segBtn = (segmentationStatus == 'completed') ? "Download ZIP" : "Unavailable"
                            const reportBtn = (reportStatus == 'completed') ? "View PDF" : "Unavailable"
                            
                            return (
                            <tr key={study.experiment_id}>
                                <td className='cell modality-cell'>{study.modality}</td>
                                <td className='cell sort-cell'>{study.patient_name}</td>
                                <td className='cell'>{study.mrn}</td>
                                <td className='cell sort-cell'>{study.insert_date}</td>
                                <td className='cell sort-cell'>{study.study_date}</td>
                                <td className='cell'>{study.study_uid}</td>
                                <td className='cell eight-rem-cell'>
                                    <Button size='sm' style={{width:"7rem"}}
                                    variant={segmentationStatus != 'completed' ? 'secondary' : 'primary'}
                                    disabled={segmentationStatus != 'completed'} 
                                    onClick={() => handleDownload(study.subject_id, study.experiment_id, 'segmentation')}>
                                        {segBtn}</Button>
                                </td>
                                <td className='cell eight-rem-cell'>
                                    <Button size='sm' style={{width:"6rem"}}
                                    variant={reportStatus != 'completed' ? 'secondary' : 'primary'}
                                    disabled={reportStatus != 'completed'} 
                                    onClick={() => handleDownload(study.subject_id, study.experiment_id, 'report')}>
                                        {reportBtn}
                                        </Button>
                                </td>
                                {adminFunctions && <td className='cell six-rem-cell'>
                                    <Button size='sm' style={{width:"4rem"}}
                                    variant='outline-danger'
                                    onClick={() => handleDelete(study.subject_id, study.study_uid, study.patient_name)}>
                                        Delete
                                    </Button>
                                </td>}
                                {adminFunctions && <td className='cell'>
                                    <Button size='sm' style={{width:"4rem"}}
                                    variant='outline-primary'
                                    onClick={() => handleSubmitWorkflow(study.subject_id, study.experiment_id, study.label)}>
                                        submit
                                    </Button>
                                    </td>}
                                {adminFunctions && <td className='cell'>{note.email}</td>}
                            </tr>
                        )})}
                    </tbody>
                </Table>
                
                {/* <Pagination>
                    <Pagination.Prev onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1} />
                    <Pagination.Item active>{currentPage}</Pagination.Item>
                    <Pagination.Next onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages} />
                </Pagination>
                */}
                <DropdownPagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    setCurrentPage={setCurrentPage}
                />
                <br/>
            </div>

            {message && <MessageWindow message={message} onClose={() => setMessage(null)} />}
            {loadingMessage && <LoadingWindow message={loadingMessage} />}
            {deleteMessage && adminFunctions &&
            <DeleteConfirmation confirmDelete={confirmDelete} 
                subject_id={deleteID} message={deleteMessage} onClose={() => {setDeleteMessage(null)}}/>}
            {submitMessage && adminFunctions &&
            <SubmitConfirmation confirmSubmit={confirmSubmit} 
                data={submitData} message={submitMessage} onClose={() => {setSubmitMessage(null)}}/>}
        </div>
    );
};

export default StudyList;
