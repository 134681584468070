import React from 'react';
import '../css/HowItWorks.css';

const Intro = () => {
    return (
        <div className="howitworks-intro">
            <div className="intro-left">
                <h2>How it works</h2>
                <p><b>TRACE</b> (Tool for Reproducible, Accurate, Contour Estimation) leverages state-of-the-art AI technology to transform the way medical imaging is analyzed and utilized. Our process ensures that you receive the most accurate and reliable data for diagnosing and monitoring Polycystic Kidney Disease (PKD).</p>
            </div> 
            <div className="intro-right">
            <ul>
                    <li>
                        <b>AI-Powered Analysis: </b>
                        Utilizing machine learning algorithms, TRACE automatically processes imaging data to extract precise measurements of total kidney volume (TKV) and other relevant biomarkers.
                    </li>
                    <li>
                        <b>Automated Contour Estimation: </b>
                        The AI technology in TRACE identifies and outlines organ contours with high accuracy, eliminating the variability and subjectivity of manual measurements.

                    </li>
                    <li>
                        <b>Reproducibility: </b>
                        Our system is designed to provide consistent results across different scans and time points, ensuring reliable data for tracking disease progression and treatment response.

                    </li>
                    <li>
                        <b>Easy Accessibility: </b>
                        TRACE tools are available both as an online platform and as a downloadable application on GitHub, making them accessible to a broad audience.

                    </li>
                    <li>
                        <b>Quality Control: </b>
                        Rigorous quality control mechanisms are integrated into TRACE to validate the accuracy and reproducibility of every measurement.
                    </li>
                </ul>
            
            </div>
        </div>
    );
};

const ForUsers = () => {
    return (
        <div className='for-section'>
            <h2>For Physicians</h2>
            <p>
                <ul>
                    <li>
                        <b>Accurate Diagnoses: </b>
                        TRACE provides reliable and precise measurements that support accurate diagnosis and monitoring of PKD, aiding in better clinical decision-making.
                    </li>
                    <li>
                        <b>Efficiency: </b>
                        Automated processes reduce the time and effort required for image analysis, allowing you to focus on patient care.
                    </li>
                    <li>
                        <b>Research and Data Collection: </b>
                        Contribute to and benefit from a growing database of patient images, enhancing the overall accuracy and generalizability of the tool.
                    </li>
                </ul>
            </p>
            <h2>For Patients</h2>
            <p>
                <ul>
                    <li>
                        <b>Empowerment: </b>
                        Access accurate and reliable information about your kidney volume and disease progression, helping you to make informed decisions about your health.

                    </li>
                    <li>
                        <b>User-Friendly Interface: </b>
                        Our intuitive platform is designed to be easily navigable, ensuring that you can use the tools with ease.
                    </li>
                    <li>
                        <b>Community Contribution: </b>
                        By sharing your imaging data, you can play a crucial role in improving the tool for everyone, contributing to better outcomes for all PKD patients.
                    </li>
                </ul>
            </p>
        </div>
    )
};

const Security = () => {
    return (
        <div className='security-section'>
            <h2>Security and Data Handling</h2>
            <div>
                <p>At <b>TRACE</b>, we prioritize the security and privacy of your data. Our protocols include: </p>
                <ul>
                    <li>
                        <b>Data Encryption: </b>
                        All data, both in transit and at rest, is encrypted using industry-standard encryption methods to protect your information from unauthorized access.
                    </li>
                    <li>
                        <b>Anonymization: </b>
                        Patients can choose to anonymize their data,and we will ensure personal identifiers are removed, maintaining privacy while allowing for meaningful analysis.
                    </li>
                    <li>
                        <b>Secure Storage: </b>
                        We use secure, compliant storage solutions to safeguard your data, ensuring it is protected against breaches and unauthorized access.

                    </li>
                    <li>
                        <b>Access Control: </b>
                        Strict access controls are in place to ensure that only authorized personnel can access sensitive data, maintaining the integrity and confidentiality of your information.
                    </li>
                    <li>
                        <b>Compliance: </b>
                        TRACE adheres to all relevant regulations and standards for data protection and privacy, including HIPAA and GDPR, ensuring that your data is handled responsibly and ethically.
                    </li>
                </ul>
            </div>
        </div>
    )
};


const HowItWorks = () => {
    return (
        <div className="how-it-works">
            <Intro />
            <ForUsers />
            <Security />
        </div>
    );
};

export default HowItWorks;
