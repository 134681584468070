import React from 'react';
import logo from '../images/logo.svg';
import '../css/Footer.css';

const Footer = () => {
    return (
        <div className="footer">
            <footer className="page-footer font-small blue pt-4">
                <div className="container-fluid text-center text-md-left">
                    <div className="row">
                        <div className="col-md-6 mt-md-0 mt-3 footer-brand">
                            <img src="/traceorg_logo_white.png"></img>
                            {/* <h5 className="text-uppercase">TRACE</h5> */}
                        </div>

                        <hr className="clearfix w-100 d-md-none pb-0" />

                        <div className="col-md-2 mb-md-0 mb-3 footer-section">
                            <h5 >Features</h5>
                            <ul className="list-unstyled">
                                <li><a href="/howitworks">How it works?</a></li>
                                <li><a href="/data-and-privacy">Data And Privacy</a></li>
                                {/* <li><a href="#!">For Non-medical Professionals</a></li> */}
                            </ul>
                        </div>

                        <div className="col-md-2 mb-md-0 mb-3 footer-section">
                            <h5>Tools</h5>
                            <ul className="list-unstyled">
                                <li><a href="/organ-volume-calculator">Organ Volume Calculator</a></li>
                                {/* <li><a href="#!">Skeletal Muscle Index Calculator</a></li>
                                <li><a href="#!">Cyst Calculator</a></li> */}
                            </ul>
                        </div>

                        <div className="col-md-2 mb-md-0 mb-3 footer-section">
                            <h5>Account</h5>
                            <ul className="list-unstyled">
                                <li><a href="/login">Sign In</a></li>
                                <li><a href="/signup">Sign Up</a></li>
                                <li><a href="/disclaimer">Terms of use</a></li>
                            </ul>
                        </div>
                    </div>
                </div>

            </footer>

            <div className="footer-copyright text-center py-3">© 2024 Copyright:
                <a href="https://traceorg.com/"> traceorg.com</a>
            </div>

        </div>
    )

};

export default Footer;