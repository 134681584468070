import React from 'react';
import '../css/FlippingCard.css';

const FlippingCard = ({ title, description, link, img }) => {
    return (
        <div className="card" onClick={() => window.location.href = link}>
            
            <div className="card-inner">
                <div className="card-front">
                    <h2>{title}</h2>
                    
                </div>
                <div className="card-back">
                    <p>{description}</p>
                    
                </div>
            </div>
        </div>
    );
};

export default FlippingCard;
