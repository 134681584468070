import React from 'react';
import { useState } from 'react';
// import { Button, Container, Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import '../css/SigninSignup.css';
import httpClient from '../utils/httpClient';
import MessageWindow from './MessageWindow';
import PageTitle from '../utils/PageTitle';

const Login = () => {
    PageTitle("Sign in | Traceorg")
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });

    const [message, setMessage] = useState()
    const [verificationCode, setVerificationCode] = useState('');
    const [isVerificationStep, setIsVerificationStep] = useState(false);

    const loginUser = async () => {
      try {
          // const response = await httpClient.post("//localhost:5050/login", formData);
          // const response = await httpClient.post("https://upload.traceorg.com/login", formData);
          const response = await httpClient.post(`${window.apiUrl}/login`, formData);
          if (response.data.message == "admin") {
              window.location.href = '/uploadstudy';
          } else {
              setMessage(response.data.message);
              setIsVerificationStep(true);
          }
      } catch (error) {
        console.log(error.response.status)
        if (error.response.status === 429) {
          setMessage("Too many attempts. Please try again later.")
        } else {
          setMessage(error.response?.data?.error || 'An error occurred.');
        }
      }
  }
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        await loginUser();
    }

    const handleVerify = async (e) => {
      e.preventDefault();
      try {
        const response = await httpClient.post(
          // '//localhost:5050/verify-login', 
          // 'https://upload.traceorg.com/verify-login',
          `${window.apiUrl}/verify-login`,
          { code: verificationCode });
        setMessage(response.data.message);
        // Redirect to protected page or update UI accordingly
        window.location.href = '/uploadstudy';
      } catch (error) {
        if (error.response.status === 429) {
          setMessage("Too many attempts. You will be blocked for 1 hour.")
        } else {
          setMessage(error.response?.data?.error || 'An error occurred.');
        }
      }
    };

    return (
    <div className="center-wrapper">
        <Container className="login-form-div">
        {!isVerificationStep ? <div className="alert alert-danger">
            Note: For better protection of your account, we now require email verification after your sign in.
            You will receive a verification code through email after you click Sign In button.
          </div> : <div className="alert alert-danger">
            Please check your email for the verification code.
            </div>}
          <Form className="login-form" onSubmit={isVerificationStep ? handleVerify : handleSubmit}>
            <h2>Sign in</h2>
            {!isVerificationStep ? <>
            <Form.Group controlId="email" style={{marginBottom:"15px"}}>
              <Form.Label>Email address</Form.Label>
              <Form.Control 
                type="email" 
                name="email"
                value={formData.email} 
                onChange={handleChange}
                placeholder="Enter email" />
            </Form.Group>

            <Form.Group controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control 
                type="password" 
                name="password"
                value={formData.password} 
                onChange={handleChange}
                placeholder="Password" />
            </Form.Group>
            {/* TODO: Forget Password */}
            {/* <a className='forget-pwd-link' href="/forget-password">Forget Password?</a> */}
            <br/>
            <Form.Group className='login-btns'>
            <Button className="signin-button" type="submit">
              Sign In
            </Button>

            <Button variant="secondary" href="/">
              Cancel
            </Button>
            </Form.Group>
            </>: <><Form.Group controlId='verification-code'>
                <Form.Label>Verification Code</Form.Label>
                  <Form.Control 
                    type="verification-code" 
                    name="verification-code"
                    value={verificationCode} 
                    onChange={e => setVerificationCode(e.target.value)}
                    placeholder="Enter your 6 digit verification code" 
                    required/>
                </Form.Group>
              <Form.Group className='login-btns'>
              <Button className="signin-button" type="submit">Verify</Button>
              <Button variant="secondary"  onClick={(e)=>{e.preventDefault();setIsVerificationStep(false)}}>
              Cancel
            </Button>
            </Form.Group></>
          }

          </Form>
          <a className='signup-link' href="/signup">Don't have an account? Sign up</a>
        </Container>
        {message && <MessageWindow message={message} onClose={() => {setMessage(null)}}/>}
      
    </div>
  );
};

export default Login;
