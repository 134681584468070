import React from 'react';
import '../css/OrganVolumeCalculator.css';
import example from '../images/example.png'

const Intro = () => {
  return (
    <div className="ovc-section">
      <div className="ovc-left">
        <img src={example}/>    
      </div>
      <div className="ovc-right">
        <h2>Organ Volume Calculator</h2>
        <p>Welcome to the Organ Volume Calculator, a key feature of TRACE designed to provide precise and reproducible measurements of total kidney volume (TKV) for patients with Polycystic Kidney Disease (PKD). Our advanced AI technology ensures that you receive accurate results quickly and efficiently.
</p>
        <a href="/uploadstudy"><button className="signup-btn">Try it out</button></a>
      </div>
    </div>
  );
};

const Instructions = () => {
    return (
        <div className='instructions-section' id='ovc-instructions'>
            <h2>Instructions</h2>
            <div className='cards-section'>
                <div className='instruction-card'>
                    {/* <h3>For Physicians</h3> */}
                    <p>
                        <ol>
                            <li>
                                <b>Upload Imaging Data: </b>
                                <br/>
                                Easily upload your patient’s imaging data in the supported formats.
                                We will process and anonymize your patient data from the uploaded files.
                            </li>
                            <li>
                                <b>Enter Basic Information: </b>
                                <br/>
                                Enter a pseudo name for the patient. Provide patient height and age for our analysis.
                                Enter an email address for receiving the report.
                            </li>
                            <li>
                                <b>Automatic Analysis: </b>
                                <br/>
                                Our AI algorithms will process the images, identifying and outlining the organ contours,
                                and generating the report based upon the countours.
                            </li>
                            <li>
                                <b>Review Results: </b>
                                <br/>
                                Receive detailed measurements of total kidney volume, along with other relevant biomarkers.
                            </li>
                            <li>
                                <b>Download Report: </b>
                                <br/>
                                Export the results in a zip format, which contain nifti file, organ segmentation, and report analysis result.
                            </li>
                        </ol>
                    </p>
                </div>
                {/* <div className='instruction-card'>
                    <h3>For Patients</h3>
                    <p>
                        <ol>
                            <li>
                                <b>Upload Your Scan: </b>
                                Securely upload your kidney imaging scans.
                            </li>
                            <li>
                                <b>Quick Analysis: </b>
                                The AI will analyze your scans and provide accurate measurements of your kidney volume.
                            </li>
                            <li>
                                <b>View Results: </b>
                                Access and understand your results through our user-friendly interface.
                            </li>
                            <li>
                                <b>Share with Your Doctor: </b>
                                Download and share the results with your healthcare provider for further consultation.
                            </li>
                        </ol>
                    </p>
                </div> */}
            </div>
        </div>
    )
};

const Testimonials = () => {
    return (
        <div className='testimonials-section'>
            <h2>Testimonials</h2>
            <div>
                <p>
                    <i>“TRACE's Organ Volume Calculator has been a game-changer in my practice. The accuracy and ease of use have significantly improved the way I monitor my patients with PKD.”</i>
                    <br/>
                    <b> -- Dr. xxx, Radiologist</b>
                </p>
                <p>
                    <i>“As a patient, TRACE has given me a clear understanding of my kidney health. The process is straightforward, and the results are easy to interpret.”</i>
                    <br/>
                    <b> -- xxx, PKD Patient</b>
                </p>
                <p>
                    <i>“Contributing my scans to TRACE has been a rewarding experience. Knowing that I’m helping to improve this tool for others is incredibly fulfilling.”</i>
                    <br/>
                    <b> -- xxx, PKD Patient</b>
                </p>
            </div>
        </div>
    )
};


const OrganVolumeCalculator = () => {
  return (
    <div className='organ-volume-calculator'>
        <Intro/>
        <Instructions/>
        <Testimonials/>
        <br/>
    </div>
  );
};

export default OrganVolumeCalculator;
