import './App.css';
import Header from './components/Header';
import UploadStudy from './components/UploadStudy';
import Login from './components/Login';
import Signup from './components/Signup';
import NotFound from './components/NotFound';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import StudyList from './components/StudyList';
import LandingPage from './components/LandingPage';
import Footer from './components/Footer';
import HowItWorks from './components/HowItWorks';
import OrganVolumeCalculator from './components/OrganVolumeCalculator';
import Instructions from './components/Instructions';
import DataAndPrivacy from './components/DataAndPrivacy';
import Disclaimer from './components/Disclaimer';
import AnonymizedTags from './components/AnonymizedTags';

function App() {

  return (
    <div className='app-container'>
      <Header/>
      <div className='main-content'>
      <BrowserRouter>
        <Routes>
          <Route path="/"  element={<LandingPage/>} />       
          <Route path="/uploadstudy"  element={<UploadStudy/>} />
          <Route path="/login"  element={<Login/>} />
          <Route path="/signup"  element={<Signup/>} />
          <Route path="/studylist"  element={<StudyList/>} />
          <Route path="/howitworks" element={<HowItWorks/>} />
          <Route path="/organ-volume-calculator" element={<OrganVolumeCalculator/>} />
          <Route path="/instructions" element={<Instructions/>} />
          <Route path="/disclaimer" element={<div className='center-wrapper'><Disclaimer/></div>} />
          <Route path="/data-and-privacy" element={<DataAndPrivacy/>}/>
          <Route path="/anonymized-tags" element={<AnonymizedTags/>}/>
          <Route path="*" element={<NotFound/>} />
        </Routes>
      </BrowserRouter>
      </div>
      <Footer/>
    </div>
  );
}

export default App;