import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

function LoadingWindow({ message }) {
  return (
    <Modal show={true} centered scrollable={true}>
        <Modal.Body >
            <br/>
            <p>{message}</p>   
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
        </Modal.Body>
    </Modal>
  );
}

export default LoadingWindow;
