import '../css/Instructions.css';
import fileExample from '../images/file-structure-example.png';
import loadmain1 from '../images/loadmain-1.png';
import loadmain2 from '../images/loadmain-2.png';
import loadseg from '../images/loadseg.png';
import loadlabel1 from '../images/loadlabel-1.png';
import loadlabel2 from '../images/loadlabel-2.png';
import volumes from '../images/volumes.png';

function Instructions() {
    return (
        <div className="center-wrapper">
            <div className="instructions">
                <h4> How to view the segmentation results</h4>

                <div className='steps'>
                    <h5>Step 1</h5>
                    <p>After downloading the ZIP, the file named "segmentation_results.zip" will 
                        be in your download folder. Extract into a folder on your hard disk.<br/>
                        If this study has psuedo study UID <i>1_23_456789</i> and the patient's psuedo name
                        is <i>John Doe</i>, the default name for the extracted folder will be 
                         <i> Doe_John-1_23_456789</i>.
                    </p>
                    <p>
                        Inside this folder, you will see some subfolders that
                        contain the results for each of the series from this study. <br/>
                        For each series, we include 4 files:
                        <p className='filename-list'>
                            <ul>
                                <li><b>nifti_img.nii.gz</b> : Nifti images for this series<br/></li>
                                <li><b>model_seg_organ.nii.gz</b> : Organ segementation image<br/></li>
                                <li><b>model_seg_parts.nii.gz</b> : Liver and kidney cyst image<br/></li>
                                <li><b>label_description.txt</b> : Label description for segementations<br/></li>
                            </ul>
                        </p>
                        <img src={fileExample}></img>
                    </p>
                </div>

                <div className='steps'>
                    <h5>Step 2</h5>
                    <p>Download <a href='http://www.itksnap.org/pmwiki/pmwiki.php?n=Downloads.SNAP4'>
                    ITK-SNAP</a> to view and correct the segmentation results.
                    </p>
                    <p className='notes'>
                        Note: ITK-SNAP is not required but recommended for correcting our segementation results.
                        <br/> We are also providing label descriptions in .txt file format, which can be handled 
                        easily by ITK-SNAP.
                    </p>
                    
                </div>

                <div className='steps'>
                    <h5>Step 3</h5>
                    <p>After installing ITK-SNAP, first drag "nifti_img.nii.gz" file into ITK-SNAP
                        to load as Main Image. 
                        Choose the correct plane for better viewability. 
                    </p>
                    <div className="img-section">
                        <img src={loadmain1}></img>
                        <img src={loadmain2}></img>
                    </div>
                </div>
                
                <div className='steps'>
                    <h5>Step 4</h5>
                    <p>Then drag "model_seg_organ.nii.gz" file into ITK-SNAP to Load as Segementation and start correcting.
                    </p>
                    <img src={loadseg}></img>
                    
                    <p className='notes'><br/>Note: If you need a complete guide on ITK-SNAP, please go to their <a href="http://www.itksnap.org/docs/fullmanual.php">user manual</a>. <br/>
                    Manual segmentation <a href="https://www.youtube.com/watch?v=OpYJiY_GoeY">tutorial</a> is also available provided by ITK-SNAP.</p>
                </div>

                <div className='steps'>
                    <h5>Step 5</h5>
                    <p>Load the label description under the Segementation tab on the top left of ITK-SNAP. You'll see the label descriptions on the left side of the ITK-SNAP.
                    </p>
                    <div className='img-section'>
                        <img src={loadlabel1}></img>
                        <img src={loadlabel2}></img>
                    </div>
                </div>

                <div className='steps'>
                    <h5>Step 6</h5>
                    <p>Correct the segementations, and view the organ volume statistics by clicking "Volume and Statistics" under the Segmentation tab.
                    </p>
                    <img src={volumes}></img>
                </div>
            </div>
           
        </div>
    )
}

export default Instructions;