import React, { useState, useEffect } from 'react';
import dicomParser from 'dicom-parser';
// import { Form, Button, Container, Row, Col, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import ImageUpload from './ImageUpload';
import MessageWindow from './MessageWindow';
import LoadingWindow from './LoadingWindow';
import JSZip, { file } from 'jszip';
import httpClient from '../utils/httpClient';
import FilteredUpload from './FilteredUpload';

const InputForm = ({ user }) => {
    const STATES = ['AK', 'AL', 'AR', 'AS', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'GU', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VI', 'VT', 'WA', 'WI', 'WV', 'WY'];
    const userEmail = user ? user.email: ''
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: userEmail,
        height: '',
        MRN: '',
        sex: '',
        dateOfBirth: '',
        age: '',
        phoneNumber: '',
        mailingAddress: '',
        city: '',
        state: '',
        zipCode: '',
    });
    const [uploadedFiles, setUploadedFiles] = useState(null);

    const [message, setMessage] = useState(null); // Error message to be passed
    const [loadingMessage, setLoadingMessage] = useState(null);
    const [hasMRN, setHasMRN] = useState(null);
    const [anonymize, setAnonymize] = useState('yes');
    const [metric, setMetric] = useState('meters');

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'mailingAddress' && value === '') {
            setFormData(prevState => ({
                ...prevState,
                mailingAddress: '',
                city: '',
                state: '',
                zipCode: ''
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
        // setShowModal(false);
    };

    const handleFileChange = (files) => {
        // console.log(files)
        if (!files || files.length === 0) {
            return;
        }
        // Set the files
        setUploadedFiles(files);
        setFormData((prevData) => ({
            ...prevData,
            'uploadedFiles': files,
        }));
    };


    const validateForm = () => {
        // console.log('inside validateform', formData)
        let errors = {};
        let formIsValid = true;
        // console.log(uploadedFiles)
        if (!uploadedFiles) {
            formIsValid = false;
            setMessage('Please upload your medical images using the Upload button');
            return formIsValid
        }

        // if (!anonymize) {
        //     formIsValid = false;
        //     setMessage('Please choose anonymization option');
        //     return formIsValid
        // }

        // if (anonymize === 'no') {
        //     return formIsValid
        // }

        if (formData.firstName && !/^[a-zA-Z]/.test(formData.firstName)) {
            formIsValid = false;
            errors.firstName = 'Please enter a valid name.';
        } else if (!formData.firstName) {
            formIsValid = false;
            errors.firstName = 'Please enter your first name.';
        }

        if (formData.lastName && !/^[a-zA-Z]/.test(formData.lastName)) {
            formIsValid = false;
            errors.lastName = 'Please enter a valid name.';
        } else if (!formData.lastName) {
            formIsValid = false;
            errors.lastName = 'Please enter your last name.';
        }

        if (!formData.email) {
            formIsValid = false;
            errors.email = 'Email is required';
        } else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+\.[a-zA-Z]+(\.[a-zA-Z]+)*$/.test(formData.email) 
        || formData.email.endsWith('.') || formData.email.startsWith('.')) {
            formIsValid = false;
            errors.email = 'Please enter a valid email';
        }

        if (!formData.height) {
            formIsValid = false;
            errors.height = 'Height is required';
        } else if (formData.height && (formData.height <= 0 || formData.height > 3.0)) {
            formIsValid = false;
            errors.height = 'Please enter a valid height in meters. (e.g., 1.73)';
        }

        if (!formData.age) {
            formIsValid = false;
            errors.age = "Patient's age is required";
        } else if (formData.age && !Number.isInteger(Number(formData.age))) {
            formIsValid = false;
            errors.age = "Please enter an integer number only";
        } else if (formData.age && (formData.age < 0 || formData.age > 150)) {
            formIsValid = false;
            errors.age = "Please enter a valid age";
        }

        // if (!formData.dateOfBirth) {
        //     formIsValid = false;
        //     errors.dateOfBirth = 'Date of birth is required';
        // }

        // Optional phone number, check 10 digit
        if (formData.phoneNumber && !/^\d{10}$/.test(formData.phoneNumber)) {
            formIsValid = false;
            errors.phoneNumber = 'Please enter a valid 10-digit phone number';
        }

        setErrors(errors);
        // console.log('inside validateform, errors', errors)
        return formIsValid;
    };

    // // Function to upload study to Ambra Health api
    // const addStudy = async (study_uid) => {
    //     // Use amplify function to call /bundle and pass in /study/add
    //     await axios.post(
    //         'https://kxogjsopyjrbnwvqjzg3awrcz40aqihn.lambda-url.us-east-2.on.aws/', 
    //         { 
    //             "sid": sid, 
    //             "study_uid": study_uid, 
    //             "storage_namespace": storage_namespace, 
    //             "phi_namespace": phi_namespace 
    //         }
    //     ).then( response => {
    //         console.log('add study response', response.data[0])
    //     }).catch( error => {
    //         console.log('Error adding study to database:', error.message)
    //         setMessage(error.message)
    //     });
    // };

    // // Function to upload Images to Ambra Health api
    // const uploadImage = async (file) => {
    //     const fileReader = new FileReader();

    //     fileReader.onload = (e) => {
    //         // Get the binary data from the file
    //         const binaryData = e.target.result;
    //         axios.post (
    //             `https://storelpa02.dicomgrid.com/api/v3/storage/namespace/${storage_namespace}/image`,
    //             binaryData,
    //             {
    //                 params: {'sid': sid},
    //             }
    //         ).then( response => {
    //             // 202 Accepted
    //             if (response.status === 202) {
    //                 setMessage("Successfully uploaded your medical images.")
    //             }
    //         }
    //         ).catch( error => {
    //             console.log("upload image failed: ", error.message)
    //             setMessage(error.message)
    //         });
    //       };

    //     // Read the contents of the DICOM file as binary data
    //     fileReader.readAsArrayBuffer(file);

    // };

    const checkPlanes = async (data) => {
        // 1. check image orientation
        const imageOrientation = data.string('x00200037')
        if (imageOrientation) {
            // Get the Image Orientation (Patient) tag and round it
            const IOPRound = imageOrientation.split("\\").map(Number).map(Math.round);
            const plane = [
                Math.abs(IOPRound[1] * IOPRound[5] - IOPRound[2] * IOPRound[4]),
                Math.abs(IOPRound[2] * IOPRound[3] - IOPRound[0] * IOPRound[5]),
                Math.abs(IOPRound[0] * IOPRound[4] - IOPRound[1] * IOPRound[3])
            ];

            if (plane[0] === 1) {
                return "sag";
            } else if (plane[1] === 1) {
                return "cor";
            } else if (plane[2] === 1) {
                return "ax";
            }
            // } else {
            //     return "Unknown";
            // }
        }

        // 2. check serial description
        const seriesDescription = data.string('x0008103e')
        const corText = ['cor', 'coronal']
        const axialText = ['ax', 'axial']
        const sagittalText = ['sag', 'sagittal']
        if (seriesDescription) {
            if (corText.some(sub => seriesDescription.toLowerCase().includes(sub))) {
                return 'cor'
            } else if (axialText.some(sub => seriesDescription.toLowerCase().includes(sub))) {
                return 'ax'
            } else if (sagittalText.some(sub => seriesDescription.toLowerCase().includes(sub))) {
                return 'sag'
            }
        }

        return 'unknown'

    }

    // Deprecated version of handleFileChange, which reads dicom files and get data
    const readDicomFile = (files) => {
        if (!files) {
            return;
        }
        const metadataArray = []

        const readFile = (file) => {
            return new Promise((resolve, reject) => {
                const fileReader = new FileReader();

                fileReader.onload = function () {
                    const arrayBuffer = this.result;
                    const byteArray = new Uint8Array(arrayBuffer);
                    const dataSet = dicomParser.parseDicom(byteArray);

                    // Extract metadata from the DICOM file
                    const studyInstanceUID = dataSet.string('x0020000d');
                    const seriesInstanceUID = dataSet.string('x0020000e');
                    const sopInstanceUID = dataSet.string('x00080018');
                    const patientName = dataSet.string('x00100010');
                    const studyDate = dataSet.string('x00080020');

                    // Add metadata to the array
                    metadataArray.push({
                        study_uid: studyInstanceUID,
                        series_uid: seriesInstanceUID,
                        instance_uid: sopInstanceUID,
                        study_date: studyDate,
                        patient_name: patientName
                    });

                    resolve();
                };

                fileReader.readAsArrayBuffer(file);
            });
        };

        const readFilesRecursively = async (index) => {
            if (index < files.length) {
                await readFile(files[index]);
                await readFilesRecursively(index + 1);
            } else {
                setUploadedFiles(files);
                setFormData({
                    ...formData,
                    'uploadedFileMetadata': metadataArray,
                    'uploadedFiles': files
                });
            }
        };

        readFilesRecursively(0);
    };

    const preprocessDicom = async (files) => {
        if (!files) {
            return;
        }

        const readFile = (file) => {
            return new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                const selectedFiles = [];

                fileReader.onload = function () {
                    const arrayBuffer = this.result;
                    const byteArray = new Uint8Array(arrayBuffer);
                    const dataSet = dicomParser.parseDicom(byteArray);
                    
                    const modality = dataSet.string('x00080060');
                    
                    if (modality.toUpperCase() === "CT") {
                        checkPlanes(dataSet)
                        .then( res => {
                            if (res === 'cor') {
                                console.log('coronal')
                                selectedFiles.push(file)
                            } else if (res === 'ax') {
                                console.log('axial')
                                selectedFiles.push(file)
                            } else if (res === 'sag') {
                                console.log('sagittal')
                            } else if (res === 'unknown') {
                                console.log('unknown')
                            }
                        }).catch( error => {
                            console.log(error)
                        })
                    }

                    console.log("this is mr", modality)
                    
                    
                    return

                    // Extract metadata from the DICOM file
                    const studyInstanceUID = dataSet.string('x0020000d');
                    const seriesInstanceUID = dataSet.string('x0020000e');
                    const sopInstanceUID = dataSet.string('x00080018');
                    const patientName = dataSet.string('x00100010');
                    const studyDate = dataSet.string('x00080020');


                    resolve();
                };

                fileReader.readAsArrayBuffer(file);
            });
        };

        const readFilesRecursively = async (index) => {
            if (index < files.length) {
                await readFile(files[index]);
                await readFilesRecursively(index + 1);
            } else {
                setUploadedFiles(files);
                setFormData({
                    ...formData,
                    'uploadedFiles': files
                });
            }
        };

        readFilesRecursively(0);
    }

    const uploadImageToXnat = async (files, subject_id, label) => {
        const zip = new JSZip();
        // Add selected files to the zip file
        files.forEach((file) => {
            zip.file(file.name, file.blob);
        });

        // Generate the zip file as a Blob
        const zipBlob = await zip.generateAsync({ type: 'blob' });

        const data = new FormData();
        data.append('file', zipBlob, 'files.zip')
        data.append('email', formData.email)
        data.append('height', formData.height)
        data.append('age', formData.age)
        const patientName = formData.lastName + '^' + formData.firstName;
        data.append('name', patientName)
        console.log(patientName)
        // return

        httpClient.post(
            `${window.apiUrl}/upload_multiple`,
            // 'https://upload.traceorg.com/upload_multiple',
            // '//localhost:5050/upload_multiple',
            data
        ).then(response => {
            // console.log(response.data)
            if (response.status === 200) {
                setMessage("Successfully uploaded your medical images.")
            }
        }).catch(error => {
            console.log(error)
            if (error.response.status === 401) {
                setLoadingMessage('Please login first. Redirecting to login page...')
                window.location.href = '/login'
            } else if (error.response.status === 409) {
                setMessage("Your images already exist in record")
            } else if (error.response.status === 400) {
                setMessage("All files are invalid files")
            } else if (error.response.status === 502) {
                setMessage("All files failed to upload.")
            } else {
                setMessage(error.message)
            }
        })
        .finally(() => {
            setLoadingMessage(null);
            setTimeout(() => {
                setLoadingMessage(null);
                window.location.href = "/studylist";
            }, 1500); 
        })
        return
    };

    // const uploadStudyToDatabase = async () => {
    //     const metadata = formData.uploadedFileMetadata[0]
    //     let data = {
    //         'patient_name': metadata.patient_name,
    //         'mrn': formData.MRN,
    //         'study_date': metadata.study_date,
    //         'study_uid': metadata.study_uid,
    //         // 'description': '',
    //     }
    //     httpClient.post("//localhost:5050/add_study", 
    //         data
    //     ).then (res => {
    //         console.log("success add to database")
    //     }).catch (err => {
    //         console.log(err)
    //     })
    // }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {

            if (!uploadedFiles) {
                console.error('File not found');
                setMessage("Please upload valid files.")
                return;
            }

            setLoadingMessage("Please wait while your images are being uploaded.")

            // // Add private tag to all files
            // const modifiedFiles = await processAndAddPrivateTag(uploadedFiles);

            // // Update the uploadedFiles array with modified files
            // setUploadedFiles(modifiedFiles);
            // console.log('modified files', uploadedFiles)


            // // Upload study&image to Weill Cornell Ambra health storage
            // await addStudy(formData['uploadedFileMetadata'][0]['study_uid']);
            // for (let i = 0; i < uploadedFiles.length; i++) {
            //     await uploadImage(uploadedFiles[i])
            // }

            // const {study_id, user_id} = await uploadStudyToDatabase();
            // const subject_id = user_id + '_' + study_id

            // Upload image to XNAT storage space
            // const resp = await httpClient.get("//localhost:5050/user");
            // // const resp = await httpClient.get("https://upload.traceorg.com/user");
            // console.log(formData)
            // const mrn = formData.MRN ? formData.MRN : 'Unknown';
            // const patient_name = formData.uploadedFileMetadata[0]['patient_name'].replace(/[\s\^]/g, "_");
            // const subject_id = resp.data.id + '-' + patient_name+ '-' + mrn;
            // const label = resp.data.id + '-' + patient_name
            //         + '-' + formData.uploadedFileMetadata[0]['study_uid'];
            // console.log(subject_id, label)

            await uploadImageToXnat(uploadedFiles);

        } else {
            console.log('Form validation failed')
        }
    };

    const handleRadioSelection = (event) => {
        setHasMRN(event.target.value);
    };

    const handleMetricChange = (e) => {
        setMetric(e.target.value);
    };

    const handleAnnonymizeChange = (e) => {
        setAnonymize(e.target.value);
    };

    const currentDate = new Date().toISOString().split('T')[0];

    const asterisk = <span className="text-danger">*</span>;

    return (
        <Form className='upload-form' onSubmit={handleSubmit}>
            <div className='upload-form-content'>
                {/* <ImageUpload onFileChange={handleFileChange} /> */}
                <div style={{ margin: '10px 0px'}}>
                <div>
                    <p style={{color: 'red'}}><b>
                        Please note that this tool is NOT FOR CLINICAL USE. 
                        All segementation results should be corrected by physicians to ensure accuracy.
                        <br/>
                        By using this tool, you acknowledge that you have read, understood, and agree to 
                        our <a href="/disclaimer">Disclaimer</a>. If you do not agree, please do not use the tool.
                    </b></p>
                    <p style={{color: 'red'}}><b>
                        Please upload DICOM images: other formats are not supported.
                        <br/>
                        For your data privacy, DICOMs are anonymized automatically on your browser locally prior to uploading.
                        For more information regarding our anonymization process, see <a href="/data-and-privacy">Data and Privacy.</a>
                    </b></p>
                    
                </div>
                <FilteredUpload onFileChange={handleFileChange}/>
                </div>

                {/* <div> 
                    <span>Do you want to upload your images anonymously?</span>
                    <br/>
                    <label style={{ padding: '5px' }}>
                        <input
                            type="radio"
                            checked={anonymize === "yes"}
                            value="yes"
                            onChange={handleAnnonymizeChange}
                        />
                        Yes
                    </label>
                    <label style={{ padding: '5px' }}>
                        <input
                            type="radio"
                            checked={anonymize === "no"}
                            value="no"
                            onChange={handleAnnonymizeChange}
                        />
                        No
                    </label>
                </div> */}

                <div>
                    <p><b>We will protect and anonymize your patient data by default.
                    <br/>Please provide necessary information for our analysis.</b></p>
                </div>

                { anonymize === "yes" && 
                <div>
                <p>Enter a pseudo name for remembering this patient:</p>
                <Row className="mb-3">
                    <Col>
                        <Form.Group as={Row} controlId="firstName">
                            <Form.Label column sm={6}>First Name{asterisk}:</Form.Label>
                            <Col sm={6}>
                                <Form.Control
                                    type="text"
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    isInvalid={!!errors.firstName}
                                />
                            </Col>
                            <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col>
                        <Form.Group as={Row} controlId="lastName">
                            <Form.Label column sm={6}>Last Name{asterisk}:</Form.Label>
                            <Col sm={6}>
                                <Form.Control
                                    type="text"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    isInvalid={!!errors.lastName}
                                />
                            </Col>
                            <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <br/>
                <p>Patient's age and height are required for report analysis:</p>
                <Form.Group as={Row} className="mb-3" controlId="age" >
                    <Form.Label column sm={4}>Patient's Age{asterisk}:</Form.Label>
                    <Col sm={5}>
                        <Form.Control
                            type="number"
                            name="age"
                            value={formData.age}
                            onChange={handleChange}
                            isInvalid={!!errors.age}
                        />
                    </Col>
                    <Form.Control.Feedback type="invalid">{errors.age}</Form.Control.Feedback>
                </Form.Group>

                {/* <Form.Group as={Row} className="mb-3" controlId="dateOfBirth" >
                    <Form.Label column sm={4}>Date of Birth{asterisk}:</Form.Label>
                    <Col sm={5}>
                        <Form.Control
                            type="date"
                            name="dateOfBirth"
                            value={formData.dateOfBirth}
                            onChange={handleChange}
                            isInvalid={!!errors.dateOfBirth}
                            max={currentDate}
                        />
                    </Col>
                    <Form.Control.Feedback type="invalid">{errors.dateOfBirth}</Form.Control.Feedback>
                </Form.Group> */}

                <Form.Group as={Row} className="mb-3" controlId="height">
                    <Form.Label column sm={4}>Height in Meters{asterisk}:</Form.Label>
                    {/* <Row> */}
                        <Col sm={3}>
                            <Form.Control
                                type="number"
                                name="height"
                                value={formData.height}
                                onChange={handleChange}
                                isInvalid={!!errors.height}
                            />
                        </Col>
                        {/* <Col sm={2}>
                            <Form.Control as="select" value={metric} onChange={handleMetricChange}>
                                <option value="inches">Inches</option>
                                <option value="feet">Feet</option>
                                <option value="meters">Meters</option>
                            </Form.Control>
                        </Col>
                    </Row> */}
                    <Form.Control.Feedback type="invalid">{errors.height}</Form.Control.Feedback>
                </Form.Group>

                <br/>
                <p>Enter an email for receiving the report:</p>
                <Form.Group as={Row} className="mb-3" controlId="email">
                    <Form.Label column sm={3}>Email{asterisk}:</Form.Label>
                    <Col sm={9}>
                        <Form.Control
                            type="text"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            isInvalid={!!errors.email}
                        />
                    </Col>
                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                </Form.Group>

                {/* Code for NYP account (MRN) or no account */}
                {/* <div>
                    <p>Do you have an NYP (NewYork-Presbyterian) Epic account? </p>
                    <label style={{ padding: '5px' }}>
                        <input
                            type="radio"
                            checked={hasMRN === "yes"}
                            value="yes"
                            onChange={handleRadioSelection}
                        />
                        Yes
                    </label>
                    <label style={{ padding: '5px' }}>
                        <input
                            type="radio"
                            checked={hasMRN === "no"}
                            value="no"
                            onChange={handleRadioSelection}
                        />
                        No
                    </label>
                </div>

                <br />
                {hasMRN === "yes" &&
                    <Form.Group as={Row} className="mb-3" controlId="MRN">
                        <Form.Label column sm={7}>Medical Record Number (MRN):</Form.Label>
                        <Col sm={5}>
                            <Form.Control
                                type="text"
                                name="MRN"
                                value={formData.MRN}
                                onChange={handleChange}
                                isInvalid={!!errors.MRN}
                            />
                        </Col>
                        <Form.Control.Feedback type="invalid">{errors.MRN}</Form.Control.Feedback>
                    </Form.Group>
                }

                {hasMRN === "no" &&
                    <div>
                        <Form.Group as={Row} className="mb-3" controlId="sex">
                            <Form.Label column sm={2}>Sex:</Form.Label>
                            <Col sm={3}>
                                <Form.Select
                                    type="text"
                                    name="sex"
                                    value={formData.sex}
                                    onChange={handleChange}
                                    isInvalid={!!errors.sex}
                                >
                                    <option value=''></option>
                                    <option value='M'>Male</option>
                                    <option value='F'>Female</option>
                                    <option value='O'>Other</option>
                                </Form.Select>
                            </Col>
                            <Form.Control.Feedback type="invalid">{errors.sex}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="phoneNumber">
                            <Form.Label column sm={4}>Phone Number:</Form.Label>
                            <Col sm={5}>
                                <Form.Control
                                    type="text"
                                    name="phoneNumber"
                                    value={formData.phoneNumber}
                                    onChange={handleChange}
                                    isInvalid={!!errors.phoneNumber}
                                />
                            </Col>
                            <Form.Control.Feedback type="invalid">{errors.phoneNumber}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="mailingAddress">
                            <Form.Label>Mailing Address:</Form.Label>
                            <Form.Control
                                type="text"
                                name="mailingAddress"
                                value={formData.mailingAddress}
                                onChange={handleChange}
                                isInvalid={!!errors.mailingAddress}
                            />
                            <Form.Control.Feedback type="invalid">{errors.mailingAddress}</Form.Control.Feedback>
                        </Form.Group>

                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="city">
                                <Form.Label>City:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="city"
                                    value={formData.city}
                                    onChange={handleChange}
                                    isInvalid={!!errors.city}
                                    disabled={!formData.mailingAddress}
                                />
                                <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} controlId="state">
                                <Form.Label>State:</Form.Label>
                                <Form.Select
                                    name="state"
                                    value={formData.state}
                                    onChange={handleChange}
                                    isInvalid={!!errors.state}
                                    disabled={!formData.mailingAddress}
                                >
                                    <option value="">Select a state</option>
                                    {STATES.map(state => (<option value={state}>{state}</option>))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">{errors.state}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} controlId="zipCode">
                                <Form.Label>Zip Code:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="zipCode"
                                    value={formData.zipCode}
                                    onChange={handleChange}
                                    isInvalid={!!errors.zipCode}
                                    disabled={!formData.mailingAddress}
                                />
                                <Form.Control.Feedback type="invalid">{errors.zipCode}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </div>
                } */}
                
                </div>
            }

            </div>

            <Button variant="outline-primary" size="lg" type="submit" className='submit-button'>
                Submit
            </Button>
            {message && <MessageWindow message={message} onClose={() => setMessage(null)} />}
            {loadingMessage && <LoadingWindow message={loadingMessage}/>}
        </Form>


    );

};

export default InputForm;