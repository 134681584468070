import blacklistConfig from '../docs/blacklistConfig.json';
import dicomTagDictionary from '@ccig/dicom-anonymizer/src/utils/dicomTagDictionary';
import Table from 'react-bootstrap/Table';

const blacklist = blacklistConfig.protocol["blacklist"]

function AnonymizedTags() {
    return (
        <div className='center-wrapper'>
            <div style={{marginTop: "20px"}}>
                <h4><b>Anonymized Data</b></h4>
                <div style={{marginTop: "20px"}}>
                    <h5><b>Special Handling:</b></h5>
                    <p>Patient ID, Patient Name, Study Instance UID, Series Instance UID, and SOP Instance UID
                        are replaced with a new value with non-reversible hash method for uniqueness.
                    </p>
                    <p>Patient's birth year is kept, while birth month and date are replaced with 01/01 (first day of the year).</p>
                    <p>Private Tags, Curve Data (50XX), and Overlay Data (60XX) are removed.</p>
                    <p>Exceptions that were kept: Acquisition Type (0019,100C), Multi-Exposure Flag (0019,100D),
                    Presentation Intent Type  (0043,1030), Presentation Type (0043,1039), Curve Label (2005,100D),
                    Curve Description (2005,100E).
                    </p>
                </div>
                <h5><b>Removed Tags</b></h5>
                <Table striped bordered>
                    <thead>
                        <tr>
                            <th>Dicom Tag</th>
                            <th>Description</th>
                        </tr>    
                    </thead>
                    <tbody>
                        {blacklist.map((tag) => {
                            return (
                                <tr>
                                    <td>{tag}</td>
                                    <td>{dicomTagDictionary[tag].name}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

export default AnonymizedTags;