import React from 'react';
// import { Modal, Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function MessageWindow({ message, onClose }) {
  return (
    <Modal show={true} onHide={onClose} centered scrollable={true}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            <p>{message}</p>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onClose}>
            Close
            </Button>
        </Modal.Footer>
    </Modal>
  );
}

export default MessageWindow;
