import React, { useRef, useEffect, useState } from 'react';
import '../css/LandingPage.css';
import img from '../images/lp-main-intro.png';
import FlippingCard from './FlippingCard';
import { BsArrowLeftCircle, BsArrowRightCircle } from "react-icons/bs";
import wcmImg from '../images/wcm.png';
import example from '../images/example.png';
import { useLocation } from 'react-router-dom';
import httpClient from '../utils/httpClient';

const Intro = ( props ) => {
    console.log(props.loggedin)
    return (
        <div className="intro-section">
            <div className="intro-left">
                <h1>Tool for </h1>
                <h1>Reproducible, </h1>
                <h1>Accurate, </h1>
                <h1>Contour </h1>
                <h1>Estimation</h1>
            </div>
            <div className="intro-right">
                <p>Welcome to TRACE, your go-to platform for advanced diagnostic 
                    tools in the field of Internal Medicine. Our mission is to 
                    revolutionize the diagnosis and monitoring of Polycystic 
                    Kidney Disease (PKD) through cutting-edge AI technology and 
                    user-friendly interfaces. TRACE empowers clinicians, researchers,
                     and patients with precise, reproducible, and predictive 
                     biomarker measurements, making critical health data readily 
                     accessible and actionable.</p>
                <div className="intro-buttons">
                    {  !props.loggedin && 
                    <a href="/signup"><button className="signup-btn">Join the Platform</button></a>
                    }
                    <a href="#tools"><button className="explore-btn">Explore Tools</button></a>
                </div>
            </div>
            
        </div>
    );
};

const Tools = () => {
    const cards = [
        { id: 1, title: 'Organ Volume Calculator', 
            description: 'Our Organ Volume Calculator is designed to provide \
            accurate and reproducible measurements of total kidney volume (TKV) \
            for patients with autosomal dominant PKD. This easy-to-use tool \
            leverages advanced AI technology to ensure that you get precise \
            results, every time and everywhere.', 
            link: '/organ-volume-calculator', img: example },
        { id: 2, title: 'Skeletal Muscle Index Calculator', description: 'Upcoming', link: '/page2' },
        { id: 3, title: 'Cyst Calculator', description: 'Upcoming', link: '/page3' },
        { id: 4, title: 'Upcoming', description: 'Upcoming', link: '/page4' },
        { id: 5, title: 'Upcoming', description: 'Upcoming', link: '/page3' },
        // Add more cards as needed
    ];

    const cardsContainerRef = useRef(null);

    const scrollLeft = () => {
        if (cardsContainerRef.current) {
            cardsContainerRef.current.scrollBy({ left: -390, behavior: 'smooth' });
        }
    };

    const scrollRight = () => {
        if (cardsContainerRef.current) {
            cardsContainerRef.current.scrollBy({ left: 390, behavior: 'smooth' });
        }
    };

    return (
        <div className='tools-section' id="tools">
            <div className='tools-text'>
                <h2>Tools</h2>
                <p>TRACE develops advanced AI tools to move beyond traditional, subjective imaging reports.</p>
            </div>
            <div className="card-section">
                <div className="cards-container" ref={cardsContainerRef}>
                    {cards.map(card => (
                        <FlippingCard key={card.id} title={card.title} description={card.description} link={card.link} img={card.img} />
                    ))}
                </div>
            </div>
            <div className="navigation-arrows">
                <button onClick={scrollLeft} className="arrow left-arrow"><BsArrowLeftCircle size={40} color="white" /></button>
                <button onClick={scrollRight} className="arrow right-arrow"><BsArrowRightCircle size={40} color="white" /></button>
            </div>

        </div>
    )
};

const Sponsors = () => {
    return (
        <div className='sponsors-section'>
            <h2>Sponsored by</h2>
            <div className='logo-section'>                
            </div>
        </div>
    )
};

const How = () => {
    return (
        <div className='how-section'
            onClick={() => { window.location.href = "/howitworks" }}>
            <div className='how-left'>
                <img src={example}></img>
            </div>
            <div className='how-right'>
                <h2>How does it work?</h2>
                <ul>
                    <li>
                    <b>AI-Powered Analysis: </b>
                    Our technology uses machine learning algorithms to analyze imaging data, providing objective and quantitative measurements of biomarker data.
                    </li>
                    <li>
                        <b>User-Friendly Interface: </b>
                        The intuitive design of our tools ensures that both professionals and patients can easily navigate and utilize the platform.

                    </li>
                    <li>
                        <b>Quality Control: </b>
                        We implement rigorous quality control mechanisms to guarantee the accuracy and reproducibility of measurements, making sure that the data you rely on is dependable.

                    </li>
                    <li>
                        <b>Community Contribution: </b>
                        Patients can contribute their imaging data to our platform, helping to train and validate our models further. This collaborative approach enhances the generalizability and accuracy of our tools.
                    </li>
                </ul>
                <p>
                    <b>TRACE</b> is setting the standard for the future of medical imaging, offering reliable and automated measurements that are essential for the effective monitoring and treatment of PKD.
                </p>
            </div>
        </div>
    )
};

const LandingPage = () => {
    const [loggedin, setLoggedin] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                // const resp = await httpClient.get("//localhost:5050/user");
                // const resp = await httpClient.get("https://upload.traceorg.com/user");
                const resp = await httpClient.get(`${window.apiUrl}/user`);
                setLoggedin(true);
            } catch (error) {
                console.log("Not logged in");
            }
        })();
    }, []);

    return (
        <div className="landing-page">
            <Intro loggedin={loggedin}/>
            <Tools />
            <Sponsors />
            <How />
        </div>
    );
};

export default LandingPage;
