import dcmjs from 'dcmjs';
import React, { useRef, useState } from 'react';
import '../css/StudyUploads.css';
import dicomParser from 'dicom-parser';
import DicomAnonymizer from '@ccig/dicom-anonymizer';
import JSZip, { file } from 'jszip';
import MessageWindow from './MessageWindow';

const FilteredUpload = ({ onFileChange }) => {
    const [progress, setProgress] = useState(0);
    const [message, setMessage] = useState(null);

    const getHierarchy = async (fileList) => {
        const hierarchy = {};

        for (const file of fileList) {
            const pathParts = file.webkitRelativePath.split('/');
            let currentLevel = hierarchy;

            for (const part of pathParts.slice(0, -1)) {
                if (!currentLevel[part]) {
                    currentLevel[part] = {};
                }
                currentLevel = currentLevel[part];
            }
            currentLevel[pathParts[pathParts.length - 1]] = file;
        }

        return hierarchy;
    };

    const corFiles = [];
    const axFiles = [];
    const sagFiles = [];
    const MRIFiles = [];

    const preprocessDicom = async (hierarchy, totalFiles) => {
        return new Promise((resolve, reject) => {

            let fileCount = 0;
            const processFiles = async (currentLevel, currentPath = '') => {
                const promises = []; // Array to hold all promises for parallel processing

                for (const key in currentLevel) {
                    if (currentLevel[key] instanceof File) {
                        const file = currentLevel[key];
                        const blackListExt = ['jpeg', 'jpg', 'png', 'pdf', 'csv', 'dll', 'exe'];
                        // Push each file processing task as a promise into the array
                        promises.push((async () => {
                            let fileExt = file.name.split('.').pop().toLowerCase();
                            if (!blackListExt.includes(fileExt)) {
                                if (await isDicomFile(file)) {
                                    const dataSet = await readFile(file);
                                    const modality = dataSet.string('x00080060');
                                    if (modality) {
                                        if (modality.toUpperCase() === 'MR') {
                                            MRIFiles.push(file)
                                        }
                                        if (modality.toUpperCase() === 'CT') {
                                            const plane = await checkPlanes(dataSet);
                                            if (plane === 'cor') {
                                                corFiles.push(file)
                                            } else if (plane === 'ax') {
                                                axFiles.push(file)
                                            } else if (plane === 'sag') {
                                                sagFiles.push(file);
                                            }
                                        }
                                    }

                                }
                            }
                            fileCount++;
                            setProgress(Math.round(fileCount / totalFiles * 100))
                        })());
                    } else {
                        // For directories or non-file entries, recursively process files
                        promises.push(processFiles(currentLevel[key], currentPath + '/' + key));
                    }
                }

                // Wait for all file and directory processing promises to complete
                await Promise.all(promises);
            };

            processFiles(hierarchy, fileCount).then(() => {
                if (MRIFiles.length) {
                    resolve(MRIFiles)
                } else if (corFiles.length) {
                    resolve(corFiles)
                } else if (axFiles.length) {
                    resolve(axFiles)
                } else if (sagFiles.length) {
                    resolve(sagFiles)
                } else {
                    resolve(null)
                }
            }).catch((error) => {
                console.log('Error processing files', error)
                resolve(error);
            })

        });
    };

    const isDicomFile = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();

            fileReader.onload = function () {
                const arrayBuffer = this.result;
                const byteArray = new Uint8Array(arrayBuffer);

                // Check for DICOM file magic number
                if (byteArray[128] === 0x44 && byteArray[129] === 0x49 && byteArray[130] === 0x43 && byteArray[131] === 0x4D) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            };

            fileReader.readAsArrayBuffer(file);
        });
    };

    const readFile = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();

            fileReader.onload = function () {
                const arrayBuffer = this.result;
                const byteArray = new Uint8Array(arrayBuffer);
                let options = {
                    untilTag: "x7fe00003"
                }
                const dataSet = dicomParser.parseDicom(byteArray, options);
                resolve(dataSet);
            };

            fileReader.readAsArrayBuffer(file);
        });
    };

    const checkPlanes = (dataSet) => {
        return new Promise((resolve, reject) => {
            try {
                // console.log(dataSet.string('x00200037'))
                // return
                if (!dataSet.string('x00200037')) {
                    resolve('unknown');
                }
                const IOP = dataSet.string('x00200037').split('\\').map(Number);

                const filePlane = (IOP) => {
                    const IOP_round = IOP.map(Math.round);
                    const plane = [
                        Math.abs(IOP_round[1] * IOP_round[5] - IOP_round[2] * IOP_round[4]),
                        Math.abs(IOP_round[2] * IOP_round[3] - IOP_round[0] * IOP_round[5]),
                        Math.abs(IOP_round[0] * IOP_round[4] - IOP_round[1] * IOP_round[3])
                    ];

                    if (plane[0] === 1) {
                        return "Sagittal";
                    } else if (plane[1] === 1) {
                        return "Coronal";
                    } else if (plane[2] === 1) {
                        return "Transverse";
                    } else {
                        return "Unknown";
                    }
                };

                const plane = filePlane(IOP);
                if (plane === 'Coronal') {
                    resolve('cor');
                } else if (plane === 'Transverse') {
                    resolve('ax');
                } else if (plane === 'Sagittal') {
                    resolve('sag');
                } else {
                    resolve('unknown');
                }
            } catch (error) {
                resolve('unknown');
            }
        });
    };

    const isLocalizer = (seriesDescription) => {
        return seriesDescription.toLowerCase().includes('localizer');
    };

    const anonymizeDicomFiles = async (files) => {
        return new Promise(async (resolve, reject) => {
            try {
                const anonymizedBlobs = await Promise.all(files.map(async (file) => {
                    const dicomAnonymizer = new DicomAnonymizer();
                    // const blacklist = dicomAnonymizer.defaultConfigs.blacklist;
                    // console.log(blacklist)
                    // blacklist.protocol.options.exceptions.push("(0010,1010)");
                    // blacklist.protocol.options.keepPatientBirthYear = true
                    // console.log(blacklist)
                    dicomAnonymizer.setConfig("blacklist");
                    const anonymizedBlob = await dicomAnonymizer.anonymizeFile(file);
                    return { name: file.webkitRelativePath, blob: anonymizedBlob };
                    // return anonymizedBlob
                }));

                resolve(anonymizedBlobs);
            } catch (error) {
                resolve(error);
            }
        });
    };

    const handleFileChange = async (event) => {
        const files = event.target.files;

        // Filter out hidden files like .DS_STORE
        if (!files) { 
            return 
        }
        const filteredFiles = Array.from(files).filter(file => !file.name.startsWith('.'));
        if (filteredFiles.length === 0) {
            return;
        }
        // Process the DICOM files
        const hierarchy = await getHierarchy(files);
        let totalFiles = files.length
        const selectedFiles = await preprocessDicom(hierarchy, totalFiles);
        if (!selectedFiles) {
            onFileChange(null)
            setMessage('Please upload valid dicom data.')
            return
        }
        anonymizeDicomFiles(selectedFiles)
            .then(async (anonymizedFiles) => {
                const zip = new JSZip();
                // Add selected files to the zip file
                anonymizedFiles.forEach((file, index) => {
                    zip.file(file.name, file.blob);
                });

                onFileChange(anonymizedFiles)
            }).catch((error) => {
                console.log("error during anonymization", error)
            })
    };


    return (
        <div>
            <input type="file" className='choose-file-btn' id="file-upload"
                onChange={handleFileChange} multiple webkitdirectory="" />
            <div>
                <progress value={progress} max="100">{progress}%</progress>
            </div>
            {message && <MessageWindow message={message} onClose={() => setMessage(null)}/>}
        </div>
    )
}

export default FilteredUpload;