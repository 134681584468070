import React from 'react';
import logo from '../images/logo.svg';
// import {Navbar, Nav, Container, NavDropdown} from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import NavDropdown from 'react-bootstrap/NavDropdown';
import httpClient from '../utils/httpClient';
import { useState, useEffect } from 'react';
import MessageWindow from './MessageWindow';
import LoadingWindow from './LoadingWindow';

export const Header = () => {
    const [user, setUser] = useState();
    const [contact, setContact] = useState();
    const [loadingMessage, setLoadingMessage] = useState();

    useEffect(() => {
        (async () => {
            try {
                // const resp = await httpClient.get("//localhost:5050/user");
                // const resp = await httpClient.get(`https://upload.traceorg.com/user`);
                const resp = await httpClient.get(`${window.apiUrl}/user`);             
                setUser(resp.data);
            } catch (error) {
                // setLoadingMessage("Please login first. Redirecting to login page...");
                // // display for 2.5 seconds
                // setTimeout(() => {
                //     setLoadingMessage(null);
                //     window.location.href = "/login";
                // }, 2500);
                console.log("Not authenticated");
            }
        })();
    }, []);

    const logoutUser = async () => {
        try {
            // await httpClient.post("//localhost:5050/logout");
            // await httpClient.post("https://upload.traceorg.com/logout");
            await httpClient.post(`${window.apiUrl}/logout`);
            window.location.href = "/";
        } catch (error) {
            console.log(error);
        }
    };

    const contactInfo = () => {
        return (
            <div>
                Please contact <a href="mailto:support@traceorg.com">support@traceorg.com</a> for help.
            </div>
        )
    };

    return (
        <Navbar expand="lg" className="bg-body-tertiary header">
            <Container>
                <Navbar.Brand href='/' className='header-brand'>
                    <img
                        src="traceorg_logo_black.png"
                    />
                    
                </Navbar.Brand>
                {/* navigation bars, go to different pages
                href to be updated */}
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end navbars" id="basic-navbar-nav">
                    <Nav >
                        <Nav.Link href="/">Home</Nav.Link>
                        <NavDropdown title="Tools" id="basic-nav-dropdown">
                            <NavDropdown.Item href="/organ-volume-calculator">
                                Organ Volume Calculator
                            </NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link onClick={() => { setContact(contactInfo) }}>
                            Contact
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                {user ? <>
                    <Nav.Link href="/studylist" style={{ 'margin': '0 10px' }}>{user.email}</Nav.Link>
                    <Nav.Link href="/" onClick={logoutUser}><button className='header-button'>Sign Out</button></Nav.Link>
                </> : <>
                    <Nav.Link href="/login"><button className='header-button'>Sign In</button></Nav.Link>
                </>}
            </Container>
            {contact && <MessageWindow message={contact} onClose={() => { setContact(null) }} />}
            {/* {loadingMessage && <LoadingWindow message={loadingMessage} onClose={() => {setLoadingMessage(null)}}/> } */}
        </Navbar>    
    );
};

export default Header;
