import React, { useEffect, useState } from 'react';
import InputForm from './InputForm';
import LoadingWindow from './LoadingWindow';
import httpClient from '../utils/httpClient';
import '../css/StudyUploads.css';
import PageTitle from '../utils/PageTitle';
import Button from 'react-bootstrap/Button';
import MessageWindow from './MessageWindow';

const UploadStudy = () => {
    PageTitle('Upload | Traceorg');
    
    const [loadingMessage, setLoadingMessage] = useState();
    const [message, setMessage] = useState();
    const [loggedOut, setLoggedOut] = useState(true);
    const [user, setUser] = useState(null);

    useEffect(() => {
        (async () => {
            try {
                // const resp = await httpClient.get("//localhost:5050/user");
                // const resp = await httpClient.get("https://upload.traceorg.com/user");
                const resp = await httpClient.get(`${window.apiUrl}/user`);
                setUser(resp.data)
                setLoggedOut(false);
            } catch (error) {
                setLoadingMessage("Please login first. Redirecting to login page...");
                // display for 2.5 seconds
                setTimeout(() => {
                    setLoadingMessage(null);
                    window.location.href = "/login";
                }, 2500); 
            } 
        })()
    }, [])

    const downloadZip = async (filename) => {
        try {
            // const response = await fetch(`http://localhost:5050/download/${filename}`, {
            // const response = await fetch(`https://upload.traceorg.com/download/${filename}`, {
            const response = await fetch(`${window.apiUrl}/download/${filename}`, {
                method: 'GET',
            });
        
            if (!response.ok) {
                setMessage('Failed to download file. Please try again later or contact our support team.');
            } else {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
            }
        } catch (error) {
            console.error('Download error:', error);
        }
      };

    return (
        <div className='center-wrapper text-center'>
            {/* {loggedOut ?
                loadingMessage ? <LoadingWindow message={loadingMessage} ></LoadingWindow> : <div></div>
                : */}
                <div className='upload-page'>
                    <br />
                    <div className='title'>
                        <h2>
                            Upload a Study
                        </h2>
                        <Button variant="outline-primary" size="lg"
                            onClick={() => { window.location.href = '/organ-volume-calculator'; }}>
                            Click here to instruction page
                        </Button>
                        <Button variant="outline-primary" size="lg"
                            onClick={() => { window.location.href = '/studylist'; }}>
                            Click here to view uploaded studies
                        </Button>
                    </div>
                    <div className='upload-form'>
                        <div className='upload-form-content'>
                        If you don't have a study on hand at the moment, feel free 
                        to use the example case we have provided. Download and unzip 
                        the file before uploading to our website. <Button 
                        variant="outline-primary" size="sm" onClick={() => downloadZip('example_dicom.zip')}>Download ZIP</Button>
                        </div> 

                    </div>
                    <InputForm user={user}/>
                </div>
            {/* } */}
            
            {message && <MessageWindow message={message} onClose={() => setMessage(null)}/>}
        </div>
    )
}

export default UploadStudy