import React from 'react';

function Disclaimer() {
  return (
    <div style={{marginBottom:'50px'}} className='signup-disclaimer'>
        <br />
        <h5>ORGAN VOLUME CALCULATOR for ADPKD </h5>
        <h5>END USER LICENSE AGREEMENT</h5>
        <p style={{fontSize:'14px', textAlign:'left'}}>IMPORTANT LEGAL NOTICE TO ALL USERS: 
            CAREFULLY READ THE FOLLOWING LEGAL AGREEMENT BEFORE YOU START USING 
            THIS  ORGAN VOLUME CALCULATOR WEB-BASED APPLICATION 
            (THE "WEB-BASED APPLICATION"). </p>

        <p style={{fontSize:'12px', textAlign:'left'}}>This End User License Agreement (this "Agreement"), is a binding 
            agreement between the traceorg.com ORGAN VOLUME CALCULATOR and all 
            affiliations (hereinafter “TOC”) and you (where "you" means (and 
            "your" refers to) any individual or legal entity that exercises 
            the permissions granted by this License). </p>

        <p style={{fontSize:'13px', textAlign:'left'}}>BY CHECKING THE "I agree to 
            the disclaimer" BOX TO SIGN UP AND CREATE A PASSWORD PROTECTED ACCOUNT, 
            YOU CONSENT TO BE BOUND BY THE 
        TERMS AND CONDITIONS OF THIS AGREEMENT. IF YOU DO NOT AGREE TO ALL OF 
        THE TERMS AND CONDITIONS OF THIS AGREEMENT, DO NOT "Sign Up", DO NOT USE OR INSTALL THE 
        WEB-BASED APPLICATION. ABSENT YOUR AGREEMENT TO THE TERMS BELOW, YOU 
        HAVE NO RIGHTS WHATSOEVER TO HOLD OR USE THE WEB-BASED APPLICATION. </p>

        <p style={{fontSize:'13px', textAlign:'left'}}>AFTER CHECKING THE "I agree to 
        the disclaimer" BOX AND SIGNING UP, YOU HAVE THE RIGHT TO USE THE 
        WEB-BASED APPLICATION IN ACCORDANCE WITH THE TERMS OF USE AND CONDITIONS OF 
        THIS AGREEMENT. </p>

        <p style={{fontSize:'13px', textAlign:'left'}}>
            <b>Web-based application Terms of Use: </b>
            <ol className="numbered-list">
                <li>
                    <b>LICENSE GRANT. </b>
                    TOC hereby grants you a limited non-exclusive license to store, 
                    load, install, execute, display and copy (to "use") the web-based 
                    application solely for the performance of internal, non-profit 
                    research conducted by you at your institution and subject to all 
                    the terms and conditions of this Agreement. 
                </li>
                <li>
                    <b>NO REDISTRIBUTION. </b>
                    The web-based application remains the property of TOC. You 
                    shall not publish, distribute, or otherwise transfer or make 
                    available the web-based application to any other party. 
                </li>
                <li>
                    <b>NO COMMERCIAL USE. </b>
                    Any use of the web-based application for commercial purposes 
                    in any form is strictly prohibited. This includes, but is not 
                    limited to, use of the web-based application in fee-for-service 
                    core laboratories or to provide services to, or commercially 
                    sponsored research for, a third party for a fee or other compensation. 
                </li>
                <li>
                    <b>OWNERSHIP AND COPYRIGHT NOTICES. </b>
                    TOC owns and shall at all times retain exclusive rights to the web-based application. This License does not transfer, assign, or convey any intellectual property rights to you (e.g., it does not transfer ownership of copyrights or trademarks). You agree not to remove or delete and shall retain in the web-based application and any modifications to the web-based application the copyright, trademark, or other notices that are provided with the web-based application. 
                </li>
                <li>
                    <b>PUBLICATION AND ATTRIBUTION. </b>
                    You have the right to publish, present, or share results of your work that makes use of the web-based application. If use of the web-based application results in results that will be published, you agree that you will acknowledge TOC as the provider of the web-based application. 
                </li>
                <li>
                    <b>NO CLINICAL USE. </b>
                    The web-based application is for academic research use only and is not approved for clinical, diagnostic or treatment purposes. You agree that you shall not use the web-based application for clinical, diagnostic or treatment purposes, and that any such uses are expressly prohibited. 
                </li>
                <li>
                    <b>NO PROTECTED HEATH INFORMATION (PHI) ALLOWED IN DICOM HEADERS. </b>
                    Any PHI in the DICOM headers that routinely contain PHI will be removed or modified to become not PHI, prior to image uploading.  TOC takes no responsibility for PHI located in non-standard DICOM headers.  The sole remedy for discovering PHI on images uploaded into TOC is deletion of those images.
                </li>
                <li>
                    <b>NO WARRANTIES; LIMITATION OF LIABILITY. </b>
                    THE WEB-BASED APPLICATION IS EXPERIMENTAL IN NATURE AND IS MADE AVAILABLE "AS IS" AND WITHOUT OBLIGATION BY TOC TO PROVIDE ACCOMPANYING SERVICES, UPDATES OR SUPPORT. ANY RISK ASSOCIATED WITH USE OF THE WEB-BASED APPLICATION IS YOUR SOLE RISK. TOC MAKES NO WARRANTIES, EXPRESS OR IMPLIED, REGARDING THE WEB-BASED APPLICATION. TOC EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES CONCERNING THE WEB-BASED APPLICATION, INCLUDING ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR ANY PARTICULAR PURPOSE, PERFORMANCE, FREEDOM FROM THE RIGHTFUL CLAIM OF ANY THIRD PARTY BY WAY OF INFRINGEMENT OR THE LIKE, AND ANY WARRANTY THAT MIGHT OTHERWISE ARISE FROM COURSE OF DEALING OR USAGE OF TRADE. 
                    <br/>
                    TO THE MAXIMUM EXTENT PERMITTED BY LAW, UNDER NO CIRCUMSTANCES, SHALL TOC OR ITS AFFILIATES BE LIABLE TO YOU OR ANY OTHER PARTY FOR DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, PUNITIVE OR EXEMPLARY DAMAGES OR RELATED EXPENSES WHICH MAY ARISE FROM THE USE OF THE WEB-BASED APPLICATION, INCLUDING BUT NOT LIMITED TO THOSE RESULTING FROM ANY DEFECT IN THE WEB-BASED APPLICATION OR DOCUMENTATION, LOSS OR INACCURACY OF DATA OF ANY KIND, LOSS OF PROFITS, INTERRUPTION OF BUSINESS, CORRUPTION, DAMAGE OR LOSS OF DATA, FAILURE OF THE WEB-BASED APPLICATION TO OPERATE WITH ANY OTHER PRODUCT, BUSINESS INTERRUPTION OR OTHERWISE, WHETHER IN CONTRACT, STRICT LIABILITY, TORT, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE. 
                </li>
                <li>
                    <b>INDEMNIFICATION. </b>
                    To the extent permitted by law, you shall indemnify, defend and hold harmless TOC, its corporate affiliates, current or future directors, trustees, officers, faculty, medical and professional staff, employees, students and agents and their respective successors, heirs and assigns (the "Indemnitees"), against any liability, damage, loss or expense (including reasonable attorney's fees and expenses of litigation) incurred by or imposed upon any of the Indemnitees in connection with any claims, suits, actions, demands or judgments arising from or connected with your use of the web-based application. TOC and TOC’s Affiliates shall have no obligation to indemnify you hereunder. This indemnification obligation shall survive expiration or termination of this Agreement. 
                </li>
                <li>
                    <b>GOVERNING LAW; Jurisdiction. </b>
                    This Agreement shall be governed by the laws of the State of New York, USA, excluding its choice-of-law principles. The application of the United Nations Convention on Contracts for the Sale of Goods is excluded. You hereby irrevocably consent to the exclusive personal jurisdiction of the New York state and US federal courts located in New York City, USA for the resolution of all disputes and controversies arising under or relating to this Agreement. 
                </li>
                <li>
                    <b>NON-USE OF NAME. </b>
                    Other than permitted under Sections 4 and 5, you will not use for publicity, promotion or otherwise, any logo, name, trade name, service mark or trademark of TOC or its Affiliates, including any simulation, abbreviation or adaptation of the same, or the name of any employee or agent of TOC or its affiliates, without TOC’s prior, written, express consent. TOC may withhold such consent in its absolute discretion.
                </li>
                <li>
                    <b>ENTIRE AGREEMENT; SEVERABILITY; NO WAIVER. </b>
                    This Agreement is the entire agreement between you and TOC and supersedes any other prior agreements, proposals, communications or advertising, oral or written, with respect to the web-based application or to subject matter of this Agreement. You acknowledge that you have read this Agreement, understand it and agree to be bound by its terms. If any provision of this Agreement is found by a court of competent jurisdiction to be invalid, void, or unenforceable for any reason, in whole or in part, such provision will be more narrowly construed so that it becomes legal and enforceable, and the entire Agreement will not fail on account thereof, and the balance of the Agreement will continue in full force and effect to the maximum extent permitted by law or equity while preserving, to the fullest extent possible, its original intent. No waiver of any provision or condition herein shall be valid unless in writing and signed by you and an authorized representative of TOC, provided that no waiver of any breach of any provisions of this Agreement will constitute a waiver of any prior, concurrent or subsequent breach. TOC's failure to insist upon or enforce strict performance of any provision of this Agreement or any right shall not be construed as a waiver of any such provision or right. 
                </li>
            </ol>
        </p>
        
        <p style={{fontSize:'12px', textAlign:'left'}}>
            <b>TOC Contact Information. </b>
            Should you have any questions concerning this Agreement, or if you desire to contact TOC for any reason, please contact <a href='mailto:support@traceorg.com'>support@traceorg.com</a>
        </p>

        <p style={{fontSize:'12px', textAlign:'left'}}>
            © 2024 TOC. All Rights Reserved. The web-based application and any and all accompanying documentation are copyrighted and protected by copyright laws and international copyright treaties, as well as other intellectual property laws and treaties. 
        </p>
    </div>
  );
}

export default Disclaimer;