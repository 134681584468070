import { Pagination, Form } from 'react-bootstrap';
import { useState, useEffect } from 'react';

export default function DropdownPagination({ currentPage, totalPages, setCurrentPage }) {
  const [selectedPage, setSelectedPage] = useState(currentPage);

  // Synchronize selectedPage with currentPage when it changes
  useEffect(() => {
    setSelectedPage(currentPage);
  }, [currentPage]);

  // Handle page selection from the dropdown
  const handleSelectPage = (e) => {
    const page = Number(e.target.value);
    setSelectedPage(page);
    setCurrentPage(page);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      {/* Previous Button */}
      <Pagination className="m-0">
        <Pagination.Prev
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
        />
      </Pagination>

      {/* Current Page Number */}
      <span>Page:</span>

      {/* Dropdown for Page Selection */}
      <Form.Select
        value={selectedPage}
        onChange={handleSelectPage}
        style={{ width: '80px' }}
        aria-label="Select page"
      >
        {Array.from({ length: totalPages }, (_, i) => (
          <option key={i + 1} value={i + 1}>
            {i + 1}
          </option>
        ))}
      </Form.Select>

      <span>of {totalPages}</span>

      {/* Next Button */}
      <Pagination className="m-0">
        <Pagination.Next
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
        />
      </Pagination>
    </div>
  );
}
