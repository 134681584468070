import { Modal, Button } from "react-bootstrap";
 
const SubmitConfirmation = ({confirmSubmit, data, message, onClose }) => {
    return (
        <Modal show={true} onHide={onClose} centered scrollable={true}>
        <Modal.Header closeButton>
          <Modal.Title>Submit Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {message}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="outline-primary" onClick={() => confirmSubmit(data) }>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    )
}
 
export default SubmitConfirmation;